
import Vue from "vue";
import charts from "@/services/chartService";

export default Vue.extend({
  name: "Dashboard",
  data() {
    return {
      selectedPeriod: "week",
      overviewWeek: undefined as undefined | any,
      overviewMonth: undefined as undefined | any,
      charts: charts
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  updated() {
    if (this.overviewWeek && this.overviewMonth) {
      const barGrapthOptions = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      };
      if (this.selectedPeriod === "week") {
        this.charts.createChart({
          data: this.overviewWeek.expensesByCategory,
          type: "bar",
          label: "Amount in EUR",
          div: this.$refs["expensesWeek"],
          options: barGrapthOptions
        });
        this.charts.createChart({
          data: this.overviewWeek.jobsStatus,
          type: "doughnut",
          label: "Amount in EUR",
          div: this.$refs["jobsWeek"],
          options: {}
        });
        this.charts.createChart({
          data: this.prepareSeriesData(
            this.overviewWeek.mileagePerDay,
            "totalMileageInKm"
          ),
          type: "line",
          label: "Mileage in Km",
          div: this.$refs["lineGraphMileageWeek"],
          options: {}
        });
        this.charts.createChart({
          data: this.prepareSeriesData(
            this.overviewWeek.mileagePerDay,
            "totalCarbonEmissionsInKg"
          ),
          type: "line",
          label: "Emissions in Kg",
          div: this.$refs["lineGraphCarbonEmissionWeek"],
          options: {}
        });
        this.charts.createChart({
          data: this.prepareSeriesData(
            this.overviewWeek.mileagePerDay,
            "totalTrips"
          ),
          type: "line",
          label: "Trips",
          div: this.$refs["lineGraphTripsWeek"],
          options: {}
        });
      } else if (this.selectedPeriod === "month") {
        this.charts.createChart({
          data: this.overviewMonth.expensesByCategory,
          type: "bar",
          label: "Amount in EUR",
          div: this.$refs["expensesMonth"],
          options: barGrapthOptions
        });
        this.charts.createChart({
          data: this.overviewMonth.jobsStatus,
          type: "doughnut",
          label: "Amount in EUR",
          div: this.$refs["jobsMonth"],
          options: {}
        });
        this.charts.createChart({
          data: this.prepareSeriesData(
            this.overviewMonth.mileagePerDay,
            "totalMileageInKm"
          ),
          type: "line",
          label: "Mileage in Km",
          div: this.$refs["lineGraphMileageMonth"],
          options: {}
        });
        this.charts.createChart({
          data: this.prepareSeriesData(
            this.overviewMonth.mileagePerDay,
            "totalCarbonEmissionsInKg"
          ),
          type: "line",
          label: "Emissions in Kg",
          div: this.$refs["lineGraphCarbonEmissionMonth"],
          options: {}
        });
        this.charts.createChart({
          data: this.prepareSeriesData(
            this.overviewMonth.mileagePerDay,
            "totalTrips"
          ),
          type: "line",
          label: "Trips",
          div: this.$refs["lineGraphTripsMonth"],
          options: {}
        });
      }
    }
  },
  async created() {
    // await this.$store.dispatch("getCurrentUser");
    await this.$store.dispatch("getOverview", "thisWeek").then(() => {
      this.overviewWeek = this.$store.state.overview;
    });
    await this.$store.dispatch("getOverview", "thisMonth").then(() => {
      this.overviewMonth = this.$store.state.overview;
    });
  },
  methods: {
    validateData(data: Record<string, number>) {
      return Object.values(data).reduce((a, b) => a + b, 0) === 0;
    },
    prepareSeriesData(
      data: {
        date: string;
        totalCarbonEmissionsInKg: number;
        totalMileageInKm: number;
        totalTrips: number;
      }[],
      value: string
    ) {
      const res: Record<string, number> = {};
      for (let i = 0; i < data.length; i++) {
        if (
          value === "totalCarbonEmissionsInKg" ||
          value === "totalMileageInKm" ||
          value === "totalTrips"
        )
          res[data[i].date] = data[i][value];
      }
      return res;
    }
  }
});
